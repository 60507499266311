import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const ColorButton = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        fontFamily: 'Helvetica-Bold, Helvetica',
        fontWeight: 'bold',
        color: '#000',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#FF773A',
            color: '#fff',
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#fff',
        },
    },
}))(Button);

export default ColorButton;
